import React from 'react'
import './Footer.css'


const Footer = () => {

  return (
    <footer>

    <p > Copyright © 2018 All rights reserved. Design by Rajat Sharma</p>
   
      
    </footer>
  )
}

export default Footer
