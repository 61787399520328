// import { LOGIN, LOGOUT } from './actionTypes';

// export const login = () => ({
//   type: LOGIN
// });

// export const logout = () => ({
//   type: LOGOUT
// });


// store.js




export function login() {
  return { type: 'LOGIN' };
}

export function logout() {
  return { type: 'LOGOUT' };
}